import React, { useState } from 'react'
import './box.css'
import MyModal from './MyModal';
const Box = ({data}) => {
       const [showModal,setShowModal] = useState(false);
       const closeModal=()=> setShowModal(false);
       return (
    <>
    <div className="box_div " id='{active}' onClick={()=>{setShowModal(true);}} >
       <h1 id='logo'>{data}</h1>
       <p id='logo2'>Unlimited Jokes on {data}</p>
    </div>
    {showModal ? <MyModal data={data} closeModal={closeModal}/>: null}
    </>
  )
}

export default Box