import React, { useEffect, useState } from 'react'
import './App.css'
import Box from './components/Box'
const App = () => {

  let Api = "https://api.chucknorris.io/jokes/categories"

  const [datas, setDatas] = useState([]);
  const fetchApiData = async (url) => {
    try {
      const res = await fetch(url);
      const data = await res.json();
      // console.log(data);
      setDatas(data);
    } catch (err) {
      console.log(err);
    }
  }
  // console.log(K);
  useEffect(() => {
    fetchApiData(Api);
  }, []);

  return (
    <div className='app_div'>
      <header className='app_header'>
        <h1 className='main'>Chuck Norries</h1>
        <div className='grid'>

          {datas.map(a => (
            <Box data={a} />
          ))}
        </div>
      </header>
    </div>
  )
}

export default App
