import { useState, useEffect } from 'react'
import './myModal.css'
import { RxCross2 } from 'react-icons/rx'

const MyModal = ({ closeModal, data }) => {
  const [datas, setDatas] = useState([])
  const [dat, setDat] = useState(false)
  let Api = "https://api.chucknorris.io/jokes/random?category=" + data
  const fetchApiData = async (url) => {
    try {
      const res = await fetch(url);
      const data = await res.json();
      // console.log(data);
      setDatas(data.value);
    } catch (err) {
      console.log(err);
    }
  }
   // console.log(K);
    useEffect(() => {
      fetchApiData(Api);
    }, []);
    function update(){
  let Kl = "https://api.chucknorris.io/jokes/random?category=" + data
  // console.log(Kl)
  const fetchApiData = async (url) => {
    try {
      const res = await fetch(url);
      const data = await res.json();
      // console.log(data);
      setDatas(data.value);
    } catch (err) {
      console.log(err);
    }
  }
  fetchApiData(Kl);
    }
  return (
    <>
      <div className="modal-container">
        <h2 className='modal_heading'>{data}</h2>
        <RxCross2 onClick={closeModal} className='x_icon' />
        <div className="contant">
          <p className='joke'>{datas}</p>
          <button className='btn' onClick={()=>update()} >Next Joke</button>
        </div>
      </div>
    </>
  )
}

export default MyModal;